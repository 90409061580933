import React from 'react';
import logo from './logo.svg';
import './App.css';

import Textfield from './Textfield'

function Abt() {
  return (
    <div className="App">
      abt
      <Textfield text={1} name="shawn" boo={true} />
    </div>
  );
}

export default Abt;
